:root {
    --default-navbar-height: 40px;
    --default-description-height: 40px;
    --default-input-height: 45px;
    --default-terms-height: 0px;
    --default-body-height:  calc(100vh -
                            (var(--default-navbar-height) + 10px +
                            var(--default-input-height) +
                            var(--default-terms-height)) -
                            var(--default-description-height));
    --default-mobile-body-height:  calc(100vh -
                            (var(--default-navbar-height) + 35px +
                            var(--default-input-height) +
                            var(--default-terms-height)) -
                            var(--default-description-height));
  }